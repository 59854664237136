
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <form @submit.prevent="addOffHourRequest" autocomplete="off">
                    <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="off_hour_request.off_hour_request_date" type="date" :label="$store.getters.language.data.off_hour_request.off_hour_request_date" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="off_hour_request.off_hour_request_amount" type="number" :label="$store.getters.language.data.off_hour_request.off_hour_request_amount" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="off_hour_request.off_hour_request_date_insert" type="datetime-local" :label="$store.getters.language.data.off_hour_request.off_hour_request_date_insert" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="off_hour_request.off_hour_request_note" type="textarea" :label="$store.getters.language.data.off_hour_request.off_hour_request_note" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['pending','approved','declined']" v-model="off_hour_request.off_hour_request_status" dense  filled outlined  item-text="off_hour_request_status"
                                    item-value="off_hour_request_status" :return-object="false" :label="$store.getters.language.data.off_hour_request.off_hour_request_status">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['off_hour','without_salary']" v-model="off_hour_request.off_hour_request_type" dense  filled outlined  item-text="off_hour_request_type"
                                    item-value="off_hour_request_type" :return-object="false" :label="$store.getters.language.data.off_hour_request.off_hour_request_type">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="users" v-model="off_hour_request.user_id" dense  filled outlined  item-text="user_id"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="employees" v-model="off_hour_request.employee_id" dense  filled outlined  item-text="employee_id"
                                    item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.off_hour_request.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers"  show-select v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="off_hour_request_id">
                    <template v-slot:[`item.off_hour_request_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/off_hour_request-list/'+item.off_hour_request_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectOffHourRequest(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteOffHourRequestList">{{$store.getters.language.data.off_hour_request.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.off_hour_request.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.off_hour_request.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteOffHourRequest(selected_off_hour_request.off_hour_request_id)">
                {{$store.getters.language.data.off_hour_request.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/off_hour_request.request.js'
    export default {
        data() {
            return {
                off_hour_request: {},
                search: '',
                loading : true,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                selected_off_hour_request : {},
                delete_dialog: false,
                headers: [

                    
                        { 
                            text: this.$store.getters.language.data.off_hour_request.off_hour_request_date,
                            align: 'start',
                            sortable: true,
                            value: 'off_hour_request_date',
                        },
                        { 
                            text: this.$store.getters.language.data.off_hour_request.off_hour_request_amount,
                            align: 'start',
                            sortable: true,
                            value: 'off_hour_request_amount',
                        },
                        { 
                            text: this.$store.getters.language.data.off_hour_request.off_hour_request_date_insert,
                            align: 'start',
                            sortable: true,
                            value: 'off_hour_request_date_insert',
                        },
                        { 
                            text: this.$store.getters.language.data.off_hour_request.off_hour_request_note,
                            align: 'start',
                            sortable: true,
                            value: 'off_hour_request_note',
                        },
                        { 
                            text: this.$store.getters.language.data.off_hour_request.off_hour_request_status,
                            align: 'start',
                            sortable: true,
                            value: 'off_hour_request_status',
                        },
                        { 
                            text: this.$store.getters.language.data.off_hour_request.off_hour_request_type,
                            align: 'start',
                            sortable: true,
                            value: 'off_hour_request_type',
                        },
                    {
                        text: this.$store.getters.language.data.users.user_id,
                        align: 'start',
                        sortable: true,
                        value: 'user_id',
                    },
                    {
                        text: this.$store.getters.language.data.employees.employee_id,
                        align: 'start',
                        sortable: true,
                        value: 'employee_id',
                    },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'off_hour_request_id',
        }
                ],
            }
        },
        computed: {
            users(){
                return this.$store.getters.users_list
            },
            employees(){
                return this.$store.getters.employees_list
            },
        },
        mounted(){
            this.readOffHourRequest();
        },
        methods: {
            addOffHourRequest() {
                this.loading_btn = true
                requests.createOffHourRequest(this.off_hour_request).then(r => {
                    if (r.status == 200) {
                        this.off_hour_request = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'OffHourRequest Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add OffHourRequest',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteOffHourRequest(off_hour_request_id) {
                requests.deleteOffHourRequest(off_hour_request_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.off_hour_request_id != off_hour_request_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'OffHourRequest Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteOffHourRequestList() {
                let ids = this.selected_rows.map(m => m.off_hour_request_id)
                requests.deleteOffHourRequestList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.off_hour_request_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.selected_rows.length+' OffHourRequest Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readOffHourRequest() {
                this.loading = true
                requests.getAllOffHourRequest().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.rows
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read OffHourRequest',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read OffHourRequest',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectOffHourRequest(i) {
                this.selected_off_hour_request = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    